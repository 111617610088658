import React from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { JobData } from './logic/postingConverter';

type JobPostingsProps = {
    posting: JobData
}

function JobPosting(props: JobPostingsProps) {
    const posting = props.posting
    const postingDate = posting.createdAt.toISOString().slice(0, 10);
    return (
        <div>
            <Card>
                <Card.Body>
                    <Card.Title>{posting.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{postingDate}</Card.Subtitle>
                    <Card.Text>
                        {posting.locationFull}
                    </Card.Text>
                <Button variant="primary" href={posting.postingUrl}>Apply</Button>
                </Card.Body>
            </Card>
        </div>
      );
    }

export default JobPosting;