import React from 'react';

function SiteHeader() {
    const headerStyle = {
        border: "2px solid",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        fontSize: "4em",
        background: "linear-gradient(#00C000,#1ED6D6)"
    }
    return (
        <div style={headerStyle}>
            JobFilter
        </div>
      );
    }

export default SiteHeader;