import React, { useState, useEffect } from 'react';
import JobPosting from './JobPostings';
import postingsJson from './postings.json'
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import convertJobPosting, { JobData, JobPostingDto } from './logic/postingConverter';
import SiteHeader from './SiteHeader';

function App() {
  const [postings, setPostings] = useState<JobPostingDto[]>([])

  const getPostings = async () => {
    const client = new S3Client({
      region: "us-east-1",
      signer: { sign: async (request) => request }
    });
    const params = {
      Bucket: "jobfilter-job-postings",
      Key: "postings.json"
    }
    
    const command = new GetObjectCommand(params);
  
    const data = await client.send(command);
    const s3Postings = await data.Body?.transformToString()
    // const s3Postings = JSON.stringify(postingsJson)
    if (s3Postings != undefined) {
      const postingsObj = JSON.parse(s3Postings)
      setPostings(postingsObj)
    } else {
      console.log("body was undefined")
    }
  }

  useEffect(() => {
    getPostings();
  }, []);

  const bodyStyle = {
    margin: "32px"
  }

  return (
    <div className="App">
      <SiteHeader/>
      <div style={bodyStyle}>
        {postings.map(post => (
          <JobPosting posting={convertJobPosting(post)}/>
        ))}
      </div>
    </div>
  );
}

export default App;
