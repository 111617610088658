export type JobPostingDto = {
    postingCompany: string,
    category: string,
    locationFull: string,
    allowedRemoteWorkLocation: string,
    fullOrPartTime: string,
    title: string,
    postingUrl: string,
    createdAt: string,
    lastUpdatedAt: string
  };

export type JobData = {
    postingCompany: string,
    category: string,
    locationFull: string,
    allowedRemoteWorkLocation: string,
    fullOrPartTime: string,
    title: string,
    postingUrl: string,
    createdAt: Date,
    lastUpdatedAt: Date
  };

function convertJobPosting(postingDto: JobPostingDto): JobData  {
    return {
        postingCompany: postingDto.postingCompany,
        category: postingDto.category,
        locationFull: postingDto.locationFull,
        allowedRemoteWorkLocation: postingDto.allowedRemoteWorkLocation,
        fullOrPartTime: postingDto.fullOrPartTime,
        title: postingDto.title,
        postingUrl: postingDto.postingUrl,
        createdAt: new Date(postingDto.createdAt),
        lastUpdatedAt: new Date(postingDto.lastUpdatedAt)
    } as JobData
}

export default convertJobPosting